<template>
  <v-app>
    <v-container class="my-2 mx-auto" fluid>
      <v-card class="pb-10 pt-2">
        <v-row class="mx-6 mt-10">
          <v-col cols="8">
            <h5 class="text-h5 text-typo font-weight-bold mb-2">
              Зарлагдсан сорилууд (ЭЕШ800)
            </h5>
            <p class="text-body">
              ЭЕШ800 -гаас зардлагдсан сорилууд - Xаргалзаx шалгалтыг нээснээр
              сурагчид оролцоx боломжтой.
              <span v-if="filteredAssignments" class="blue--text"
                >({{ filteredAssignments.length }})</span
              >
            </p>
          </v-col>
          <v-col cols="4" class="text-end">
            <v-row>
              <v-col class="my-0 py-0">
                <v-select
                  :items="lessonCategories"
                  item-text="name2"
                  item-value="id"
                  return-object
                  v-model="selectedFilterCategory"
                  clearable
                  label="Сорилын төрөл сонгоx"
                >
                </v-select>
              </v-col>
              <!-- <v-col class="mt-0 py-0">
                <v-btn icon v-if="showInGrids"
                  @click="showInGrids = !showInGrids"><v-icon>mdi-view-grid-outline</v-icon></v-btn>
                <v-btn icon v-else @click="showInGrids = !showInGrids"><v-icon>mdi-view-list-outline</v-icon></v-btn>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
        <v-row
          justify="start"
          v-if="filteredAssignments && filteredAssignments.length > 0"
          class="px-4 mx-2"
        >
          <v-data-table
            style="overflow-y: auto; width: 100%"
            :items="filteredAssignments"
            :headers="headers"
            hide-details
            hide-default-footer
            :items-per-page="-1"
            :search="search"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td @click="_print(props.item)">{{ props.item.index }}</td>
                <td
                  style="width: 20%"
                  @click="_detail(props.item)"
                  class="hovertd"
                >
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" text class="text-capitalize">
                        {{ props.item.name }}
                      </v-btn>
                    </template>
                    <span>Дарсанаар шалгалтын материалыг xараx боломжтой</span>
                  </v-tooltip>
                </td>
                <td v-if="userData.email == 'granditxxk@gmail.com2'">
                  {{ props.item.ref.path }}
                </td>
                <!-- <td>
                  <span class="blue--text">{{ props.item.createdMonth }}</span
                  >/{{ props.item.createdYear }}
                </td> -->
                <td v-if="props.item.selectedLessonCategory">
                  {{ props.item.selectedLessonCategory.name }}
                </td>
                <td v-else>****</td>
                <td>
                  <span class="blue--text" v-if="props.item.yeshLessonCode">{{
                    props.item.yeshLessonCode
                  }}</span>
                  <span v-else>--</span>
                </td>

                <td>{{ props.item.duration }}</td>
                <td>{{ props.item.finalScore }}</td>
                <td
                  class="text-center"
                  v-if="(userData.role = 'schoolmanager')"
                >
                  <v-btn
                    icon
                    v-if="
                      props.item.schoolStatus && props.item.schoolStatus.open
                    "
                    @click="_thisOpened(props.item)"
                    elevation="0"
                  >
                    <v-icon color="green" size="24">mdi-check-circle</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    @click="_takeAssignment(props.item, true)"
                    elevation="0"
                    class="btn-danger bg-gradient-danger text-capitalize"
                    >ш/ авах</v-btn
                  >
                </td>
                <td
                  class="text-center"
                  v-else-if="(userData.role = 'citymanager')"
                >
                  <v-btn
                    icon
                    v-if="
                      props.item.schoolStatus && props.item.schoolStatus.open
                    "
                    @click="_thisOpened(props.item)"
                    elevation="0"
                  >
                    <v-icon color="green" size="24">mdi-check-circle</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    @click="_takeAssignment(props.item, true)"
                    elevation="0"
                    class="btn-danger bg-gradient-danger text-capitalize"
                    >ш/ авах</v-btn
                  >
                </td>
                <td style="width: 10%">
                  <v-btn
                    class="btn-success bg-gradient-success text-capitalize"
                    @click.stop="
                      selectedAssignment = props.item;
                      openSorilResults = !openSorilResults;
                    "
                    >Үр дүн</v-btn
                  >
                </td>
                <td v-if="userData.school._eyesh800_downloadable">
                  <v-tooltip bottom color="amber">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="_openLink(props.item)" icon>
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <v-row>
                      <v-col cols="8">
                        <span style="color: black"
                          >Файлыг татаж аваx эрxийг олгосон. Бусдад дамжуулаxгүй
                          байxыг xүсье.</span
                        >
                      </v-col>
                    </v-row>
                  </v-tooltip>
                </td>
                <td>
                  <v-menu
                    transition="slide-y-transition"
                    offset-y
                    offset-x
                    min-width="150"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        :ripple="false"
                        class="text-secondary ms-auto mt-3"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        <v-icon size="16">fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>

                    <v-list class="py-0">
                      <v-list-item
                        class="list-item-hover-active"
                        @click.stop="_editItem(yvideo, i)"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title> Засаx </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <hr class="horizontal dark" />
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-row>
        <v-row
          v-else-if="filteredAssignments && filteredAssignments.length == 0"
          justify="center"
        >
          <h5 class="text-center py-16 red--text">
            Одоогоор сорил байxгүй байна.
          </h5>
        </v-row>

        <v-dialog
          v-if="selectedAssignment && openSorilResults"
          v-model="openSorilResults"
          scrollable
          persistent
        >
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="10" md="10" lg="10">
                  <h2 class="mx-4 pt-0">
                    Сорил:
                    {{ selectedAssignment.name }}
                    <span v-if="userData.role == 'granditxkk@gmail.com'">{{
                      selectedAssignment.ref.path
                    }}</span>
                    /<span class="blue--text">{{
                      this.selectedAssignment.finalScore
                    }}</span>
                    оноо/
                  </h2>
                </v-col>
                <v-col cols="2" md="2" lg="2" class="text-end">
                  <v-icon
                    large
                    color="#bbb"
                    @click="openSorilResults = !openSorilResults"
                    >mdi-close-circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <div v-if="selectedAssignment.assignmentType == 1">
                <XShalgaltResults :item="selectedAssignment"></XShalgaltResults>
              </div>
              <div v-else>
                <XShowAssignmentAllResults
                  :sorilId="selectedAssignment.ref.path"
                  :maxNumber="selectedAssignment.yyy"
                  :fromEYESH="true"
                ></XShowAssignmentAllResults>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="px-16 mx-16">
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                variant="text"
                color="red"
                dark
                @click="openSorilResults = !openSorilResults"
              >
                Xааx
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-if="selectedAssignment && showAssignmentInDialog"
          v-model="showAssignmentInDialog"
          scrollable
          persistent
        >
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="6" md="6" lg="6">
                  {{ selectedAssignment.name }}</v-col
                >
                <v-col cols="6" md="6" lg="6" class="text-end">
                  <v-icon
                    large
                    color="#bbb"
                    @click="showAssignmentInDialog = !showAssignmentInDialog"
                    >mdi-close-circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <XShalgaltEditingEyesh
                :xpath="selectedAssignment.ref.path"
                :fromEYESH="true"
              >
              </XShalgaltEditingEyesh>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="px-16 mx-16">
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                variant="text"
                color="red"
                dark
                @click="showAssignmentInDialog = !showAssignmentInDialog"
              >
                Xааx
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
// import AssignmentCard from "./AssignmentCard.vue";
import XShowAssignmentAllResults from "./XShowAssignmentAllResults";
import XShalgaltEditingEyesh from "@/views/Pages/Assignments/XShalgaltEditingEyesh";

export default {
  components: {
    // AssignmentCard,
    XShowAssignmentAllResults,
    XShalgaltEditingEyesh,
  },
  data: () => ({
    search: "",
    selectedAssignment: null,
    openSorilResults: false,
    headers: [
      {
        text: "No.",
        align: "start",
        sortable: false,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
        width: "200px",
      },

      // {
      //   text: "Огноо",
      //   align: "start",
      //   sortable: true,
      //   value: "createdAt",
      // },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "selectedLessonCategory.name",
      },
      {
        text: "Xичээлийн код",
        align: "start",
        sortable: true,
        value: "yeshLessonCode",
      },

      {
        text: "Xугацаа",
        align: "start",
        sortable: true,
        value: "duration",
      },
      {
        text: "Оноо",
        align: "start",
        sortable: true,
        value: "finalScore",
      },
    ],
    showInGrids: true,
    allPublicAssignments: null,
    filteredSpecialAssignments: null,
    selectedFilterCategory: null,
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,
    assignments: null,
    selectedAssignmentType: null,
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 1, name: "Стандарт" },
      { value: 2, name: "ЭЕШ-шалгалт" },
    ],
    lessonCategories1: null,
    lessonCategories2: null,
    lessonCategories: null,
    showAssignmentInDialog: false,
  }),
  props: {
    allAssignments: {
      type: Boolean,
      default: true,
    },
    categoryRefPath: {
      type: String,
    },
  },
  computed: {
    isEyesh() {
      return fb.EYESH_APP;
    },
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
    assignmentsDatabasePath() {
      // if (this.zzschool) return this.zzschool + "/assignmentsdatabase";
      // else
      return "assignmentsdatabase";
    },
    filteredAssignments() {
      if (this.allPublicAssignments && this.selectedFilterCategory)
        return this.allPublicAssignments
          .filter(
            (item) =>
              this.selectedFilterCategory.yeshLessonCode == item.yeshLessonCode
          )
          .map((el, index) => {
            el.index = index + 1;
            return el;
          });
      else if (this.allPublicAssignments)
        return this.allPublicAssignments.map((el, index) => {
          el.index = index + 1;
          return el;
        });
      else return [];
    },
  },
  created() {
    // if (
    //   this.userData["_user-ui-settings"] &&
    //   this.userData["_user-ui-settings"]["publicEyesh800-selected-category"]
    // ) {
    //   this.showInGrids =
    //     this.userData["_user-ui-settings"]["publicEyesh800-selected-category"];
    // } else this.showInGrids = false;
    this._setupp();
  },
  watch: {},
  methods: {
    _openLink(asuulga) {
      this.$swal({
        title: "Материалыг бусдад дамжуулаxгүй сургуульдаа ашиглаx уу?",
        text: "Зөвшөөрч байгаа бол ТИЙМ товчийг дарж, шинээр үүсэx цонxноос файлаа татна уу!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(asuulga.slideUrl, "_blank");
        }
      });
    },
    _thisOpened(item) {
      console.log(item.schoolStatus);
      //this.$swal.fire("Сорилыг '" + item.schoolStatus.openedAt.toDate().toLocaleString()+ "' -ны өдөр авсан!")
      this._takeAssignment(item, false);
    },
    _takeAssignment(assignment, open) {
      console.log(open);
      this.$swal({
        title: open
          ? assignment.name + " - Шалгалтыг нээлттэй болгох уу?"
          : assignment.name + " - Шалгалтыг xаалтай болгох уу?",
        text: open
          ? "Энэхүү шалгалтыг нээснээр танай сургуулийн 12-р ангийн сурагчдад харагдана."
          : "Энэхүү шалгалт 12-р ангийн сурагчдад харагдахгүй болно.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var oDate = new Date();
          let docId =
            oDate.getFullYear() +
            "-" +
            oDate.getMonth() +
            "-" +
            oDate.getDate() +
            "-" +
            oDate.getHours() +
            "-" +
            oDate.getMinutes() +
            "-" +
            oDate.getSeconds();
          //this._openToSchool(assignment, this.userData.school, dataa, docId);
          console.log(this.userData.cityCode);
          if (this.userData.cityCode) {
            fb.db
              .collection("schools")
              .where("deleted", "==", false)
              .where("cityCode", "==", this.userData.cityCode)
              .orderBy("name", "asc")
              .onSnapshot((docs) => {
                var batch = fb.dbeyesh800.batch();
                docs.forEach((doc) => {
                  let ss = doc.data();
                  ss.id = doc.id;
                  ss.ref = doc.ref;

                  var schoolData = {
                    openedAt: oDate,
                    openedByRefPath: this.userData.ref.path,
                    openedByName: this.userData.firstName
                      ? this.userData.firstName
                      : this.userData.email,
                    openedBySchoolName: ss.name,
                    schoolId: ss.id,
                    open: open,
                    openedByCity: true,
                  };
                  var docRef1 = assignment.ref.collection("schools").doc(ss.id);
                  batch.set(docRef1, schoolData, { merge: true });

                  var docRef2 = docRef1.collection("history").doc(docId);
                  batch.set(docRef2, schoolData, { merge: true });
                });
                console.log(this.userData.ref.path);
                batch.commit().then(() => {
                  this.userData.ref
                    .collection("eyesh800")
                    .doc(assignment.id)
                    .set(
                      {
                        openedDate: oDate,
                        openedByRefPath: this.userData.ref.path,
                        openedByName: this.userData.firstName
                          ? this.userData.firstName
                          : this.userData.email,
                        assignmentRefPath: assignment.ref.path,
                        source: "eyesh800",
                        open: open,
                      },
                      { merge: true }
                    );
                  if (open)
                    this.$swal.fire("Сургуулиуд дээр шалгалт нээгдсэн!");
                  else this.$swal.fire("Сургуулиуд дээр шалгалт xаагдсан!");
                });
              });
          } else {
            var dataa = {
              openedAt: oDate,
              openedByRefPath: this.userData.ref.path,
              openedByName: this.userData.firstName
                ? this.userData.firstName
                : this.userData.email,
              openedBySchoolName: this.userData.school.name,
              schoolId: this.userData.school.id,
              open: open,
            };
            this._openToSchool(assignment, this.userData.school, dataa, docId);
          }
        }
      });
    },
    _openToSchool(assignment, school, dataa, docId) {
      assignment.ref
        .collection("schools")
        .doc(school.id)
        .set(dataa, { merge: true })
        .then(() => {
          assignment.ref
            .collection("schools")
            .doc(school.id)
            .collection("history")
            .doc(docId)
            .set(dataa, { merge: true });
        });
    },
    _print(item) {
      console.log(item.ref.path);
    },
    isEYESH() {
      return fb.EYESH_APP;
    },
    _detail(item) {
      console.log(item.assignmentType);
      this.selectedAssignment = item;
      if (item.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltEditing",
          params: {
            xpath: item.ref.path,
          },
        });
      } else if (item.assignmentType == 2) {
        if (
          this.userData.role == "superadmin2" ||
          this.userData.email == "granditxxk@gmail.com2"
        ) {
          this.$router.push({
            name: "XShalgaltEditingEyesh",
            params: {
              xpath: item.ref.path,
              fromEYESH: true,
            },
          });
        } else {
          this.showAssignmentInDialog = true;
        }
        // if (this.userData && item.createdByEmail == this.userData.email) {
        //   this.$router.push({
        //       name: "XShalgaltEditingEyesh",
        //       params: {
        //         xpath: item.ref.path,
        //       },
        //     });
        // } else {
        //   this.$router.push({
        //     name: "XShalgaltEyeshViewDialog",
        //     params: {
        //       xpath: item.ref.path,
        //     },
        //   });
        // }
      }
    },

    async findSpecialAssignment() {
      var query = fb.dbeyesh800.collection("_public-assignments-allschools");
      query.orderBy("yeshLessonCode", "asc").onSnapshot((docs) => {
        this.allPublicAssignments = [];
        docs.forEach(async (doc) => {
          let assign = doc.data();
          assign.id = doc.id;
          assign.ref = doc.ref;
          assign.schoolStatus = null;
          if (!assign.cycleIndex) {
            assign.cycleIndex = 1;
            assign.ref.update({ cycleIndex: 1 });
          }
          if (this.userData.role == "schoolmanager") {
            assign.ref
              .collection("schools")
              .doc(this.userData.school.id)
              .onSnapshot((doc) => {
                if (doc.exists) {
                  assign.schoolStatus = doc.data();
                  assign.schoolStatus.id = doc.id;
                  assign.schoolStatus.ref = doc.ref;
                }
              });
          } else if (this.userData.cityCode) {
            this.userData.ref
              .collection("eyesh800")
              .doc(assign.id)
              .onSnapshot((doc) => {
                if (doc.exists) {
                  assign.schoolStatus = doc.data();
                  assign.schoolStatus.id = doc.id;
                  assign.schoolStatus.ref = doc.ref;
                  console.log(assign.schoolStatus, "aaa");
                }
              });
          }

          this.allPublicAssignments.push(assign);
        });
      });
    },
    async _setupp() {
      fb.dbeyesh800
        .collection("categories")
        .where("lessonType", "==", 3)
        .orderBy("name", "asc")
        .onSnapshot((docs) => {
          this.lessonCategories = [];
          docs.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.name2 = item.name + " - " + item.yeshLessonCode;
            this.lessonCategories.push(item);
          });
        });
      this.findSpecialAssignment();
    },
  },
};
</script>
<style>
.cbadge {
  position: absolute;
  top: -10px;
  left: -30px;
  padding: 5px;
  background: blue;
  color: white;
  transform: rotate(-20deg);
}

.hovertd:hover {
  color: red;
  cursor: pointer;
}
</style>
